<template>
  <div>
    <loading-component v-if="isLoading"></loading-component>

    <div class="ManifestDocument" v-else>
      <div id="PrintManifestAction">
        <h4>Print Manifest</h4>
        <v-btn @click="print" color="warning">Print</v-btn>
      </div>

      <div class="Manifest" id="Manifest">
        <div class="Manifest-header">
          <img :src="trollyLogo" alt="shipping logo" />

          <table style="border-spacing: 0; text-align: center">
            <thead style="color: white; font-weight: 200">
              <td
                style="
                  border: 1px solid black;
                  padding: 2px 5px;
                  background: grey;
                "
              >
                Tujuan
              </td>
              <td
                style="
                  border: 1px solid black;
                  padding: 2px 5px;
                  background: grey;
                "
              >
                Kepada Yth. Cabang
              </td>
            </thead>

            <tbody>
              <tr>
                <td style="border: 1px solid black; padding: 2px 5px">
                  {{ hubCitySniCode }}
                </td>
                <td style="border: 1px solid black; padding: 2px 5px">
                  <!-- {{ hubName }} -->
                  co-loader
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="Manifest-content">
          <div>
            <p>PT ETRANS INTRALOGISTIK INDONESIA</p>
            <p>Telp: +62 877-7373-2800, Email: halo@trolly.asia</p>
          </div>

          <div style="text-align: center">
            <p><strong>DAFTAR KIRIMAN</strong></p>
            <p><strong>(MANIFEST)</strong></p>
            <p>
              <strong>{{ manifestId }}</strong>
            </p>
            <p>
              <strong>Tanggal: {{ manifestDate }}</strong>
            </p>
          </div>

          <div>
            <p>No Polisi :</p>
            <p>Driver :</p>
            <p>Jenis Armada :</p>
            <p>No Hp :</p>
          </div>
        </div>

        <div class="Manifest-order">
          <table>
            <thead>
              <tr>
                <th>No.</th>
                <th>No. AWB</th>
                <th>Tujuan</th>
                <th>Pengirim</th>
                <th>Penerima</th>
                <th>Isi Barang</th>
                <th>Koli</th>
                <th>Kilo</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(order, index) in listOrder" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ order.WBNumber }}</td>
                <td>
                  {{
                    order.Order.Recipient
                      ? order.Order.Recipient.Kota
                      : order.Order.RecipientInter.Alamat
                  }}
                </td>
                <td>{{ order.Order.ShipperLoc.Nama }}</td>
                <td>
                  {{
                    order.Order.Recipient
                      ? order.Order.Recipient.Nama
                      : order.Order.RecipientInter.Nama
                  }}
                </td>
                <td>{{ order.Keterangan }}</td>
                <td>{{ order.TotalKoli }}</td>
                <td style="text-align: right">
                  {{ Number(order.TotalBerat) }}
                </td>
              </tr>
            </tbody>

            <tr>
              <td colspan="5"></td>
              <td class="sum">Total</td>
              <td class="sum">{{ totalColly }}</td>
              <td class="sum" style="text-align: right">{{ totalWeight }}</td>
            </tr>
          </table>
        </div>
        <div class="Manifest-footer">
          <div>
            <table>
              <tr>
                <td style="width: 100px">Nama</td>
                <td style="width: 100px"></td>
              </tr>
              <tr>
                <td>Tanggal</td>
                <td style="width: 200px"></td>
              </tr>

              <tr>
                <td colspan="2" style="text-align: center">Paraf</td>
              </tr>

              <tr>
                <td colspan="2" style="height: 100px">
                  <div
                    class="d-flex·flex-column·justify-end·align-center"
                    style="height: 100%"
                  >
                    ( Diserahkan Oleh )
                  </div>
                </td>
              </tr>
            </table>
          </div>

          <div>
            <table>
              <table>
                <tr>
                  <td style="width: 100px">Nama</td>
                  <td style="width: 100px"></td>
                </tr>
                <tr>
                  <td>Tanggal</td>
                  <td style="width: 200px"></td>
                </tr>

                <tr>
                  <td colspan="2" style="text-align: center">Paraf</td>
                </tr>

                <tr>
                  <td colspan="2" style="height: 100px">
                    <div
                      class="d-flex·flex-column·justify-end·align-center"
                      style="height: 100%"
                    >
                      ( Diterima Oleh )
                    </div>
                  </td>
                </tr>
              </table>
            </table>
          </div>
        </div>
      </div>

      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :manual-pagination="true"
        :pdf-quality="2"
        pdf-format="a4"
        pdf-content-width="207mm"
        pdf-orientation="portrait"
        ref="html2PdfInvoice"
        :html-to-pdf-options="htmlToPdfOptions"
      >
        <section slot="pdf-content">
          <v-app>
            <v-main>
              <div class="sprint" id="Manifest">
                <div>
                  <table style="border-spacing: 0" width="100%">
                    <tr style="border: none !important">
                      <td rowspan="2" width="65%">
                        <img
                          :src="trollyLogo"
                          alt="shipping logo"
                          width="40%"
                        />
                      </td>
                      <td
                        style="
                          border: 1px solid black !important;
                          padding: 2px 5px;
                          background: grey;
                          text-align: center;
                          color: white;
                        "
                      >
                        Tujuan
                      </td>
                      <td
                        style="
                          border: 1px solid black !important;
                          padding: 2px 5px;
                          background: grey;
                          text-align: center;
                          color: white;
                        "
                      >
                        Kepada Yth. Cabang
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="
                          border: 1px solid black !important ;
                          text-align: center;
                          padding: 2px 5px;
                        "
                      >
                        {{ hubCitySniCode }}
                      </td>
                      <td
                        style="
                          border: 1px solid black !important;
                          text-align: center;
                          padding: 2px 5px;
                        "
                      >
                        <!-- {{ hubName }} -->
                        co-loader
                      </td>
                    </tr>
                  </table>
                </div>

                <table style="margin-top: 30px; font-size: 14px; width: 100%">
                  <tr>
                    <td style="width: 45%" valign="top">
                      <div>
                        PT ETRANS INTRALOGISTIK INDONESIA<br />
                        Telp: +62 877-7373-2800, Email: halo@trolly.asia
                      </div>
                    </td>
                    <td style="width: 25%">
                      <div style="text-align: center">
                        <strong>DAFTAR KIRIMAN</strong><br />
                        <strong>(MANIFEST)</strong><br />
                        <strong>{{ manifestId }}</strong
                        ><br />
                        <strong>Tanggal: {{ manifestDate }}</strong
                        ><br />
                      </div>
                    </td>
                    <td style="width: 30%">
                      <div>
                        No Polisi :<br />
                        Driver :<br />
                        Jenis Armada :<br />
                        No Hp :<br />
                      </div>
                    </td>
                  </tr>
                </table>

                <div style="margin-top: 30px">
                  <table
                    style="width: 100%; font-size: 14px; font-weight: bold"
                  >
                    <thead style="border: 1px solid black !important">
                      <tr style="background-color: grey; color: white">
                        <th>No.</th>
                        <th>No. AWB</th>
                        <th>Tujuan</th>
                        <th>Pengirim</th>
                        <th>Penerima</th>
                        <th>Isi Barang</th>
                        <th>Koli</th>
                        <th>Kilo</th>
                      </tr>
                    </thead>

                    <tbody style="border: 1px solid black !important">
                      <tr v-for="(order, index) in listOrder" :key="index">
                        <td style="border: 1px solid grey !important">
                          {{ index + 1 }}
                        </td>
                        <td style="border: 1px solid grey !important">
                          {{ order.WBNumber }}
                        </td>
                        <td style="border: 1px solid grey !important">
                          {{
                            order.Order.Recipient
                              ? order.Order.Recipient.Kota
                              : order.Order.RecipientInter.Alamat
                          }}
                        </td>
                        <td style="border: 1px solid grey !important">
                          {{ order.Order.ShipperLoc.Nama }}
                        </td>
                        <td style="border: 1px solid grey !important">
                          {{
                            order.Order.Recipient
                              ? order.Order.Recipient.Nama
                              : order.Order.RecipientInter.Nama
                          }}
                        </td>
                        <td style="border: 1px solid grey !important">
                          {{ order.Keterangan }}
                        </td>
                        <td style="border: 1px solid grey !important">
                          {{ order.TotalKoli }}
                        </td>
                        <td
                          style="
                            border: 1px solid grey !important;
                            text-align: right;
                          "
                        >
                          {{ Number(order.TotalBerat) }}
                        </td>
                      </tr>
                    </tbody>

                    <tr>
                      <td colspan="5"></td>
                      <td style="border: 1px solid grey !important" class="sum">
                        Total
                      </td>
                      <td style="border: 1px solid grey !important" class="sum">
                        {{ totalColly }}
                      </td>
                      <td
                        style="
                          border: 1px solid grey !important;
                          text-align: right;
                        "
                        class="sum"
                      >
                        {{ totalWeight }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div style="margin-top: 40px">
                  <table style="width: 100%">
                    <tr>
                      <td>
                        <div>
                          <table style="width: 100%">
                            <tr>
                              <td
                                style="
                                  width: 100px;
                                  border: 1px solid black !important;
                                "
                              >
                                Nama
                              </td>
                              <td
                                style="
                                  width: 100px;
                                  border: 1px solid black !important;
                                "
                              ></td>
                            </tr>
                            <tr>
                              <td style="border: 1px solid black !important">
                                Tanggal
                              </td>
                              <td
                                style="
                                  width: 200px;
                                  border: 1px solid black !important;
                                "
                              ></td>
                            </tr>

                            <tr>
                              <td
                                colspan="2"
                                style="
                                  text-align: center;
                                  border: 1px solid black !important;
                                "
                              >
                                Paraf
                              </td>
                            </tr>

                            <tr>
                              <td
                                colspan="2"
                                style="
                                  height: 100px;
                                  border: 1px solid black !important;
                                "
                              >
                                <div
                                  class="d-flex flex-column justify-end align-center"
                                  style="height: 100%"
                                >
                                  ( Diserahkan Oleh )
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                      <td>
                        <div>
                          <table style="width: 100%">
                            <tr>
                              <td
                                style="
                                  width: 100px;
                                  border: 1px solid black !important;
                                "
                              >
                                Nama
                              </td>
                              <td
                                style="
                                  width: 100px;
                                  border: 1px solid black !important;
                                "
                              ></td>
                            </tr>
                            <tr>
                              <td style="border: 1px solid black !important">
                                Tanggal
                              </td>
                              <td
                                style="
                                  width: 200px;
                                  border: 1px solid black !important;
                                "
                              ></td>
                            </tr>

                            <tr>
                              <td
                                colspan="2"
                                style="
                                  text-align: center;
                                  border: 1px solid black !important;
                                "
                              >
                                Paraf
                              </td>
                            </tr>

                            <tr>
                              <td
                                colspan="2"
                                style="
                                  height: 100px;
                                  border: 1px solid black !important;
                                "
                              >
                                <div
                                  class="d-flex flex-column justify-end align-center"
                                  style="height: 100%"
                                >
                                  ( Diterima Oleh )
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </v-main>
          </v-app>
        </section>
      </vue-html2pdf>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import LoadingComponent from "../../components/dashboard/LoadingComponent.vue";
import trollyLogo from "../../assets/images/trolly-logo.png";
import { baseUrl } from "../../constant/url";
import axios from "axios";
import { getTokenCookie } from "../../utils/cookies";
import { formatToRupiah } from "../../utils/formatter";
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: { LoadingComponent, VueHtml2pdf },
  name: "Manifest",
  data() {
    return {
      isLoading: false,
      trollyLogo,
      totalWeight: "",
      totalColly: "",
      totalPrice: "",
      listOrder: [],
      manifestId: "",
      manifestDate: "",
      hubName: "",
      hubCitySniCode: "",
      htmlToPdfOptions: {
        margin: 0,
        DisablePdfCompression: 1,
        image: {
          type: "jpeg",
          quality: 0.95,
        },

        enableLinks: false,

        html2canvas: {
          dpi: 300,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },

        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
        },
      },
    };
  },
  methods: {
    print() {
      // window.print();
      try {
        window.scrollTo({ top: 0 });
        this.$refs.html2PdfInvoice.generatePdf();
      } catch (error) {
        console.log(error);
      }
    },
    toRupiah(value) {
      return formatToRupiah(value);
    },
    fetchDetail() {
      this.isLoading = true;
      const manifestId = this.$route.query.manifestId;

      axios({
        url: `${baseUrl}/HubManifest/${manifestId}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.isLoading = false;
          const { data } = res.data;
          let totalKoli = 0;
          let totalWeight = 0;
          let totalPrice = 0;

          data.orders.forEach((el) => {
            totalKoli += Number(el.TotalKoli);
            totalWeight += Number(el.TotalBerat);
            totalPrice += Number(el.TotalBiaya);
          });

          this.totalWeight = totalWeight;
          this.totalColly = totalKoli;
          this.totalPrice = totalPrice;

          this.listOrder = data.orders;
          this.manifestId = data.detail.IDManifest;
          this.manifestDate = moment(data.detail.Date).format("YYYY-MM-DD");
          if (this.listOrder.length > 0) {
            Vue.set(this, "hubName", this.listOrder[0].Order.Hub.NamaHub);
            Vue.set(
              this,
              "hubCitySniCode",
              this.listOrder[0].Order.Recipient
                ? this.listOrder[0].Order.Recipient.City.CitySNI
                : this.listOrder[0].Order.RecipientInter.Country.CodeCountry
            );
          }
          this.isLoading = false;
          let self = this;

          setTimeout(function () {
            self.print();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
  },
  created() {
    this.fetchDetail();
  },
};
</script>

<style lang="scss" scoped>
.ManifestDocument {
  background-color: grey;
  min-height: 100vh;

  #PrintManifestAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    padding: 10px 20px;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: 1;

    h4 {
      color: white;
    }
  }

  .Manifest {
    max-width: 45rem;
    background-color: white;
    margin: auto;
    padding: 10px;
    border: 1px solid grey;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      img {
        width: 10rem;
      }
    }

    &-content {
      margin: 20px auto;
      display: flex;
      gap: 60px;

      p {
        margin: 0;
        font-size: 12px;
      }
    }

    &-order {
      margin: 20px auto;
      table {
        border-spacing: 0;
        width: 100%;

        tbody {
          border: 1px grey solid;
          box-shadow: 0 0 0 1px grey;

          td {
            text-align: center;
            font-size: 12px;
          }
        }

        .sum {
          background: grey;
          color: white;
          text-align: center;
          border: 1px black solid;
          padding: 2px;
          font-size: 12px;
        }

        th {
          background-color: grey;
          box-shadow: 0 0 0 1px black;
          color: white;
          font-weight: 400;
          border: 1px black solid;
          text-align: center;
          padding: 2px 5px;
          font-size: 12px;
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      font-size: 12px;

      table {
        border-spacing: 0;
        td {
          border: 1px solid black;
          padding: 2px;
        }
      }
    }
  }
}

@media print {
  .ManifestDocument {
    background-color: white;
  }

  #PrintManifestAction {
    visibility: hidden;
  }

  #Manifest {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 45rem;
    margin: 10px auto;
  }
}

.sprint {
  padding: 10px;
  table,
  th,
  td {
    border: none !important;
    border-collapse: collapse;
    padding: 5px !important;
    // border: none !important;
  }
}
</style>
